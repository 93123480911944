import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useJobOrders() {
  const statuses = ref([])

  const jobOrders = ref([])
  const jobOrder = ref({})
  const loading = ref(false)
  const searchJobOrder = ref(null)
  const risItems = ref([])

  const maintenanceTypes = ref(['Preventive', 'Predictive', 'Corrective', 'Emergency', 'Other'])

  const getStatuses = () => {
    axios.get('/job-order-statuses').then(r => {
      statuses.value = r.data
    })
  }

  const getJobOrders = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/job-orders/filter', params)
        .then(response => {
          const { data } = response.data
          jobOrders.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getRISItems = controlNumber => {
    const res = new Promise((resolve, reject) => {
      axios
        .get(`/ris?control_number=${controlNumber}`)
        .then(response => {
          const { data } = response
          risItems.value = data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })

    return res
  }

  const getJobOrder = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/job-orders/${id}`)
        .then(response => {
          const { data } = response
          jobOrder.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchJobOrder,
    jobOrders,
    jobOrder,
    statuses,
    maintenanceTypes,
    risItems,

    // functions
    getJobOrders,
    getJobOrder,
    getStatuses,
    getRISItems,
  }
}
