import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useMakes() {
  const makes = ref([])
  const loading = ref(false)
  const searchMake = ref(null)

  const getMakes = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/makes/filter', params)
        .then(response => {
          const { data } = response.data
          makes.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchMakes = () => {
    getMakes()
  }

  return {
    // vars
    loading,
    searchMake,
    makes,

    // function
    getMakes,
    fetchMakes,
  }
}
