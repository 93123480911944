<template>
  <v-card>
    <v-card-title>Job Orders</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          {{ actionAlertMessage }}
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-if="!advancedFilter"
        v-model="searchQuery"
        :placeholder="!vehicle ? 'Search Plate Number, Control Number' : 'Search Control Number'"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="fetchJobOrders"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="$store.getters['auth/can'](['create job orders'])"
          color="primary"
          class="mb-4 me-3"
          @click="create"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Job Order</span>
        </v-btn>

        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="advancedFilter = !advancedFilter"
        >
          <v-icon>{{ icons.mdiFilterOutline }}</v-icon>
          <span v-if="!advancedFilter">Advanced Filter</span>
          <span v-else>Hide Filter</span>
        </v-btn>

        <!-- <v-btn
          color="secondary"
          outlined
          disabled
          class="mb-4"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn> -->
      </div>
    </v-card-text>
    <v-card-text v-if="advancedFilter">
      <v-row>
        <v-col
          cols="12"
          :md="!vehicle ? 4 : 12"
        >
          <v-autocomplete
            v-model="formSearch.job_order_status_id"
            aria-required=""
            placeholder="Search.. ."
            :items="statuses"
            item-value="id"
            item-text="status"
            cache-items
            hide-details="auto"
            label="Status"
            outlined
            dense
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col
          v-if="!vehicle"
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.vehicle_type_id"
            placeholder="Search.. ."
            :loading="loadingType"
            :items="types"
            :search-input.sync="searchType"
            cache-items
            hide-details="auto"
            label="Type"
            item-text="type"
            item-value="id"
            outlined
            dense
            clearable
            @keyup.enter="fetchTypes"
          ></v-autocomplete>
        </v-col>
        <v-col
          v-if="!vehicle"
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.make_id"
            aria-required=""
            placeholder="Search.. ."
            :loading="loadingMake"
            :items="makes"
            :search-input.sync="searchMake"
            cache-items
            hide-details="auto"
            label="Make"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            @change="formSearch.make_model_id = null; models=[]"
            @keyup.enter="fetchMakes"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="formSearch.make_id && !vehicle"
          cols="12"
          md="12"
        >
          <v-autocomplete
            v-model="formSearch.make_model_id"
            placeholder="Search.. ."
            :loading="loadingModel"
            :items="models"
            :search-input.sync="searchModel"
            hide-details="auto"
            label="Model"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            @keyup.enter="fetchModels"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="!vehicle">
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.department_id"
            placeholder="Search.. ."
            :loading="loadingDepartment"
            :items="departmentOptions"
            :search-input.sync="searchDepartment"
            cache-items
            hide-details="auto"
            label="Department"
            item-value="id"
            item-text="name"
            outlined
            dense
            hide-no-data
            clearable
            @keyup.enter="fetchDepartments"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="formSearch.plate_number"
            label="Plate Number"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="formSearch.engine_number"
            label="Engine Number"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.date_type"
            aria-required=""
            placeholder="Search.. ."
            :items="['Issue Date', 'Start Date', 'Completion Date']"
            hide-details="auto"
            label="Date Type"
            outlined
            dense
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formSearch.date_from"
                label="From Date"
                :prepend-icon="icons.mdiCalendarCheckOutline"
                readonly
                v-bind="attrs"
                outlined
                dense
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formSearch.date_from"
              color="primary"
              @input="dateFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formSearch.date_to"
                label="To Date"
                :prepend-icon="icons.mdiCalendarCheckOutline"
                readonly
                v-bind="attrs"
                outlined
                dense
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formSearch.date_to"
              color="primary"
              @input="dateTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :disabled="advancedSearching"
              @click="advancedSearch"
            >
              <span>Search</span>
            </v-btn>
            <v-btn
              class="mb-4 me-3"
              outlined
              @click="resetFilter"
            >
              <span>Reset Filter</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="jobOrders"
        :options.sync="options"
        :server-items-length="totalJobOrders"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- control_number -->
        <template #[`item.control_number`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'job-orders-edit', params: { id: item.id } }"
            >
              {{ item.control_number }}
            </router-link>
          </div>
        </template>

        <!-- pre_repair -->
        <template #[`item.pre_repair`]="{ item }">
          <v-btn
            v-if="item.pre_repair_id"
            color="success"
            dark
            x-small
            fab
            :to="{ name: 'pre-repairs-edit', params: { id: item.pre_repair_id } }"
          >
            <v-icon dark>
              {{ icons.mdiProgressWrench }}
            </v-icon>
          </v-btn>
        </template>

        <!-- plate_number -->
        <template #[`item.plate_number`]="{ item }">
          <div class="d-flex flex-column">
            {{ item.vehicle.plate_number }}
          </div>
        </template>

        <!-- vehicle -->
        <template #[`item.vehicle`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'vehicles-view', params: { id: item.vehicle.id } }"
            >
              {{ item.vehicle.make.name }} {{ item.vehicle.make_model.name }}
            </router-link>
            <span class="text-xs">{{ item.vehicle.vehicle_type ? item.vehicle.vehicle_type.type : '' }}</span>
          </div>
        </template>

        <!-- status  -->
        <template #[`item.status`]="{item}">
          <v-chip
            :class="`v-chip-light-bg ${item.job_order_status.color}--text`"
            :color="item.job_order_status.color"
            small
          >
            {{ item.job_order_status.status }}
          </v-chip>
        </template>

        <!-- start_date  -->
        <template #[`item.start_date`]="{item}">
          <span>{{ resolveDate(item.start_date) }}</span>
        </template>

        <!-- completion_date  -->
        <template #[`item.completion_date`]="{item}">
          <span v-if="item.completion_date">{{ resolveDate(item.completion_date) }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if=" $store.getters['auth/can'](['edit job orders'])"
                @click="editItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.waste_report"
                @click="viewWasteReport(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteVariant }}
                  </v-icon>
                  <span>View Waste Report</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item :to="{ name: 'job-orders-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item
                v-if=" $store.getters['auth/can'](['delete job orders'])"
                @click="deleteItemConfirm(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="dialogDelete = !dialogDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItem"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiFilterOutline,
  mdiPencilOutline,
  mdiDeleteVariant,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCarPickup,
  mdiProgressWrench,
  mdiCalendarCheckOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import useJobOrders from './useJobOrders'
import useMakes from '../vehicles/useMakes'
import useTypes from '../vehicles/useTypes'
import useModels from '../vehicles/useModels'
import useDepartments from './useDepartments'
import router from '@/router'
import useUIResolver from '../useUIResolver'
import store from '@/store'

const formSearchInit = () => ({
  job_order_status_id: null,
  vehicle_type_id: null,
  make_id: null,
  make_model_id: null,
  engine_number: null,
  plate_number: null,
  department_id: null,
  date_from: null,
  date_to: null,
})

const defaultAlertMessage = 'JobOrder successfully saved.'

export default {
  props: {
    vehicle: {
      default: () => null,
      type: Number,
    },
  },
  setup() {
    const advancedSearching = ref(false)
    const formSearch = ref(formSearchInit())
    const advancedFilter = ref(false)
    const dateFrom = ref(false)
    const dateTo = ref(false)
    const jobOrderData = ref({})
    const actionAlert = ref(false)
    const actionAlertMessage = ref('')
    const searchQuery = ref('')
    const totalJobOrders = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dialogDelete = ref(false)
    const headers = ref([])

    const { statuses, getStatuses, jobOrders, getJobOrders } = useJobOrders()
    const { jobOrderTableHeaders, resolveDate } = useUIResolver()
    const { makes, getMakes, loading: loadingMake, searchMake } = useMakes()
    const { models, getModels, loading: loadingModel, searchModel } = useModels()
    const { types, getTypes, loading: loadingType, searchType } = useTypes()
    const {
      departments: departmentOptions,
      getDepartments,
      searchDepartment,
      loading: loadingDepartment,
    } = useDepartments()

    const fetchJobOrders = () => {
      advancedSearching.value = true
      const params = { ...options.value, ...{ searchQuery: searchQuery.value }, ...formSearch.value }
      if (router.currentRoute.name === 'vehicles-view') {
        params.vehicle_id = router.currentRoute.params.id
      }
      getJobOrders(params).then(response => {
        totalJobOrders.value = response.data.total
        options.value.itemsPerPage = response.data.per_page
        advancedSearching.value = false
      })
    }

    onMounted(() => {
      getStatuses()
      actionAlertMessage.value = defaultAlertMessage
      headers.value = jobOrderTableHeaders()
      if (router.currentRoute.name === 'vehicles-view') {
        // unset VEHICLE header
        headers.value = jobOrderTableHeaders().filter(header => !['vehicle', 'plate_number'].includes(header.value))
      }
    })

    const fetchMakes = () => {
      getMakes({ searchQuery: searchMake.value })
    }

    const fetchTypes = () => {
      getTypes({ searchQuery: searchType.value })
    }

    const fetchModels = () => {
      getModels({ make_id: formSearch.value.make_id, searchQuery: searchModel.value })
    }

    const fetchDepartments = () => {
      getDepartments({ search: searchDepartment.value })
    }

    const clearSearch = () => {
      searchQuery.value = null
      fetchJobOrders()
    }

    const create = () => {
      if (router.currentRoute.name === 'vehicles-view') {
        router.push({ name: 'job-orders-create', query: { vehicle_id: router.currentRoute.params.id } })
      } else {
        router.push({ name: 'job-orders-create' })
      }
    }

    const saveJobOrder = () => {
      actionAlertMessage.value = defaultAlertMessage
      actionAlert.value = true
      fetchJobOrders()
    }

    const editItem = item => {
      router.push({ name: 'job-orders-edit', params: { id: item.id } })
    }

    const viewWasteReport = item => {
      router.push({ name: 'waste-reports-edit', params: { id: item.waste_report.id } })
    }

    const deleteItem = () => {
      axios.delete(`/job-orders/${jobOrderData.value.id}`).then(response => {
        actionAlertMessage.value = 'JobOrder successfully deleted.'
        actionAlert.value = true

        // hide confirm alert
        dialogDelete.value = false
        fetchJobOrders()
      })
    }

    const advancedSearch = () => {
      fetchJobOrders()
    }

    const resetFilter = () => {
      formSearch.value = formSearchInit()
      fetchJobOrders()
    }

    const deleteItemConfirm = item => {
      jobOrderData.value = item
      dialogDelete.value = true
    }

    watch(options, fetchJobOrders)

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    return {
      advancedSearching,
      dialogDelete,
      actionAlert,
      actionAlertMessage,
      jobOrderData,
      formSearch,
      headers,
      searchQuery,
      totalJobOrders,
      footerProps,
      options,
      resolveDate,
      dateFrom,
      dateTo,
      advancedFilter,

      // useJobOrders
      statuses,
      getStatuses,
      jobOrders,
      getJobOrders,

      // useMakes
      makes,
      getMakes,
      loadingMake,
      searchMake,
      fetchMakes,

      // useModels
      models,
      getModels,
      loadingModel,
      searchModel,
      fetchModels,

      // useTypes
      types,
      getTypes,
      loadingType,
      searchType,
      fetchTypes,

      // useDepartments
      departmentOptions,
      fetchDepartments,
      searchDepartment,
      loadingDepartment,

      // functions
      clearSearch,
      create,
      saveJobOrder,
      fetchJobOrders,

      editItem,
      viewWasteReport,
      deleteItem,
      deleteItemConfirm,

      advancedSearch,
      resetFilter,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiFilterOutline,
        mdiPencilOutline,
        mdiDeleteVariant,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCarPickup,
        mdiProgressWrench,
        mdiCalendarCheckOutline,
      },
    }
  },
}
</script>
