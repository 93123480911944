import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useModels() {
  const models = ref([])
  const loading = ref(false)
  const searchModel = ref(null)

  const getModels = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/make-models/filter', params)
        .then(response => {
          const { data } = response.data
          models.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // vars
    loading,
    searchModel,
    models,

    // function
    getModels,
  }
}
