import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useDepartments() {
  const department = ref({})
  const departments = ref([])
  const searchDepartment = ref('')
  const loading = ref(false)

  const getDepartments = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/departments', { params })
        .then(response => {
          const { data } = response.data
          departments.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          loading.value = false
          reject(error)
        })
    })
  }

  const getDepartment = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/departments/${id}`)
        .then(response => {
          const { data } = response
          department.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          loading.value = false
          reject(error)
        })
    })
  }

  return {
    department,
    departments,
    searchDepartment,

    getDepartment,
    getDepartments,
  }
}
