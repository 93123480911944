import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useTypes() {
  const types = ref([])
  const loading = ref(false)
  const searchType = ref(null)

  const getTypes = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/vehicle-types/filter', params)
        .then(response => {
          const { data } = response.data
          types.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // vars
    loading,
    searchType,
    types,

    // function
    getTypes,
  }
}
